export const HOVER_OPACITY = 0.5
export const EXTRA_HOVER_OPACITY = 0.5
export const LARGE_BUTTON_DIM = 36
export const BUTTON_DIM = 32
export const SMALL_BUTTON_DIM = 28
export const EXTRA_SMALL_BUTTON_DIM = 24
export const SECTION_HEADER_FONT_SIZE = 17
export const MODAL_HEADING_TO_CONTENT_MARGIN = 0
export const MODAL_SECTION_VERTICAL_SPACE = 24
export const MODAL_SECTION_VERTICAL_SPACE_SMALLER = 16
export const MODAL_TOP_TO_BUTTONS_BAR_VERTICAL_SPACE = 16
export const MODAL_TOP_TO_TITLE_VERTICAL_SPACE = 40
export const MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE = 40
export const DASHBOARD_CLEANING_STATUS_FILTER = 'dashboard-cleaning-status-filter'
export const DASHBOARD_PRIORITY_FILTER = 'dashboard-priority-filter'
export const ISSUE_ROW_CONTEXT_HOUSEKEEPING_MODAL = 'housekeeeping-modal'
export const BUTTON_SEPERATOR_HORIZONTAL = 24

export const MOBILE_BREAKPOINT = 991
export const MEDIUM_DESKTOP_SCREEN_HEIGHT = 962
export const SMALL_DESKTOP_SCREEN_HEIGHT = 768

export const EMAIL_STORAGE_ID = 'emailForSignIn'

export const PMS_NAMES = {
    BOOKING_FACTORY: 'Booking Factory',
    APALEO: 'Apaleo',
    BEDS_24: 'beds24',
    GODO: 'Godo Property',
    RENTLIO: 'Rentlio',
    NO_PMS: 'nopms',
    MEWS: 'Mews',
    BOOKING_AUTOMATION: 'Booking Automation',
    CLOUDBEDS: 'Cloudbeds',
    GUESTY: 'Guesty',
    HOST: 'Host',
    GUESTLINE: 'Guestline',
    ORACLE: 'Oracle',
    BLUE_LAGOON: 'Blue Lagoon'
}

export const INTEGRATIONS = {
    BOOKING_FACTORY: 'bookingfactory',
    APALEO: 'apaleo',
    BEDS_24: 'beds24' as const,
    BEDS_24_v2: 'beds24v2',
    RENTLIO: 'rentlio',
    NO_PMS: 'nopms',
    MEWS: 'mews',
    CLOUDBEDS: 'cloudbeds',
    GUESTY: 'guesty',
    HOST: 'host',
    GUESTLINE: 'guestline',
    ORACLE: 'oracle',
    BLUE_LAGOON: 'bluelagoon'
}

export const BRANDS = {
    SWEEPLY: 'sweeply'
}

export const DEVICES = {
    DESKTOP: 'desktop',
    ANDROID: 'android',
    IOS: 'ios'
}

export const LOCAL_STORAGE = {
    APALEO_SUBJECT_ID: 'apaleo_subjectId',
    IFRAME_INITIAL_SEARCH_PARAMS: 'iframe_initial_search_params'
}

export const INSIDE_IFRAME = window.self !== window.top
