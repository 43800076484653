import moment from 'moment-timezone'
import unorm from 'unorm'
import { CountryCode, parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'

import * as c from './txt-constants'
import * as colors from './colors'

import { iOSColors } from './react-native-typography'
import { AreaStruct, IssueItem, IssueStruct, TaskStruct, UserStruct } from './firestore-structs'
import { Moment } from 'moment-timezone'

export function getFirstname(fullName: string) {
    return fullName.split(' ').slice(0, -1).join(' ')
}

export function getLastName(fullName: string) {
    return fullName.split(' ').slice(-1).join(' ')
}
export function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}

interface PageIsNotExist {
    totalCount: number
    pageNumber: number
    pageSize: number
}
export function pageIsNotExist({ totalCount, pageNumber, pageSize }: PageIsNotExist) {
    const pagesQuantity = Math.ceil(totalCount / pageSize)
    return pageNumber > pagesQuantity
}

// / Validate E164 phoneNumber format
export function validPhoneNumber(phoneNumber: string) {
    return parsePhoneNumberFromString(phoneNumber)
}

export function cleanHashTags(str: string) {
    const regexp = new RegExp('#([^\\s]*)', 'g')
    const postText = str.replace(regexp, '')
    return postText.trim()
}

export function hashTagsToString(hashTags: string[]) {
    let result = ''
    hashTags.forEach(hashTag => {
        result += hashTag + ' '
    })
    if (result !== '') {
        result = result.substring(0, result.length - 1)
    }
    return result
}

export function getHashTags(string: string) {
    let hashTags = null
    let i
    let len
    let word
    const words = string.split(/[\s\r\n]+/)
    hashTags = []
    for (i = 0, len = words.length; i < len; i++) {
        word = words[i]
        if (word.indexOf('#') === 0) {
            hashTags.push(word)
        }
    }
    return hashTags
}

export function removeHashtag(str: string, tagToRemove: string) {
    if (tagToRemove.startsWith('#')) {
        let result = cleanHashTags(str)
        const hashTagsInStr = getHashTags(str)
        hashTagsInStr.forEach(tagInStr => {
            if (tagToRemove !== tagInStr) {
                result += ' ' + tagInStr
            }
        })
        return result.trim()
    }
    return str
}

export function removeHashtags(str: string, tagsToRemove: string[]) {
    if (tagsToRemove.length === 0) {
        return str
    }
    let result = str
    tagsToRemove.forEach(tagToRemove => {
        result = removeHashtag(result, tagToRemove)
    })

    return result
}

export function sortByName(a: string, b: string) {
    const aUnorm = normalizeString(a)
    const bUnorm = normalizeString(b)
    return aUnorm.localeCompare(bUnorm, 'en', { sensitivity: 'base' })
}

export function sortTimeStampDescending(a: number, b: number) {
    if (a == null || b == null) return 0
    let result = 0

    let aTimeStamp = a
    let bTimeStamp = b

    if (aTimeStamp === undefined) aTimeStamp = 0
    if (bTimeStamp === undefined) bTimeStamp = 0

    result = bTimeStamp - aTimeStamp
    return result
}

export function sortTimeStampAscending(a: number, b: number) {
    if (a == null || b == null) return 0
    let result = 0

    let aTimeStamp = a
    let bTimeStamp = b

    if (aTimeStamp === undefined) aTimeStamp = 0
    if (bTimeStamp === undefined) bTimeStamp = 0

    result = aTimeStamp - bTimeStamp
    return result
}

export function shortenLongName(str: string, limit: number) {
    if (!str) {
        return ''
    }
    const dots = '...'
    if (str.length > limit) {
        str = str.substr(0, limit) + dots
    }

    return str
}

export function getTimestampFromId(id: string) {
    const PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz'
    let time = 0
    const data = id.substr(0, 8)

    for (let i = 0; i < 8; i += 1) {
        time = time * 64 + PUSH_CHARS.indexOf(data[i])
    }

    return time
}

export function addNewline(str: string, limit: number) {
    const rest = str.substr(limit - 1, str.length - 1)
    if (str.length > limit) {
        str = str.substr(0, limit) + '- \n' + rest
    }

    return str
}

/*
    Temporary type definition
    TODO - need to figure out how to define this type properly
*/
export function isItemTextAvailable(item: IssueItem) {
    const val =
        item.text !== null &&
        item.text !== undefined &&
        item.text.content !== null &&
        item.text.content !== undefined &&
        item.text.content !== ''
    return val
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function unCapitalizeFirstLetter(string: string) {
    return string.charAt(0).toLowerCase() + string.slice(1)
}

export function getInitials(name: string) {
    if (!name) return 'N/A'
    // const initials = Array.prototype.map.call(name.split(' '), x => x.substring(0, 1).toUpperCase()).join('')
    const initials = name
        .replace(/[^A-Za-z0-9À-ÿ ]/gi, '') // taking care of accented characters as well
        .replace(/ +/gi, ' ') // replace multiple spaces to one
        .split(/ /) // break the name into parts
        .reduce((acc, item) => acc + item[0], '') // assemble an abbreviation from the parts
        .concat(name.substr(1)) // what if the name consist only one part
        .concat(name) // what if the name is only one character
        .concat(name) // what if the name is only one character
        .substr(0, 3) // get the first three characters an initials
        .toUpperCase()
    return initials
}

export function getPluralForPhotos(count: number) {
    if (count === 1) {
        return 'photo'
    }
    return 'photos'
}

export function getPlural(text: string, count: number) {
    if (count === 1) {
        return text
    }
    return text + 's'
}

export function getIssueNumberColor(status: IssueStruct['status'] | 'reset') {
    if (status === c.ISSUE_OPEN) {
        return '#000000'
    }
    if (status === c.ISSUE_ASSIGNED) {
        return colors.yello_spectaflow
    }
    if (status === c.ISSUE_COMPLETE) {
        return iOSColors.green
    }
    if (status === c.ISSUE_DELETED) {
        return colors.red_spectaflow
    }

    return '#000000'
}
/*
    Temporary type definition
    TODO - need to figure out how to define this type properly
*/
type OrgStatus = 'Active' | 'Ready' | 'Suspended' | 'Trial'
export function pickStatusColor(orgStatus: OrgStatus) {
    if (orgStatus === 'Active') {
        return iOSColors.green
    } else if (orgStatus === 'Ready') {
        return colors.green_spectaflow
    } else if (orgStatus === 'Suspended') {
        return colors.red_spectaflow
    } else if (orgStatus === 'Trial') {
        return colors.yello_spectaflow
    } else {
        return iOSColors.gray
    }
}

export function filterUsersByUsersAreaGroups(currentUser: UserStruct, users: UserStruct[]) {
    const currentUserGroups = currentUser.areaGroups ? currentUser.areaGroups : []

    return users.filter(user => {
        const intersection = currentUserGroups.filter(x => user.areaGroups && user.areaGroups.includes(x))
        return intersection.length > 0 || (user.areaGroups.length === 1 && user.areaGroups[0] === 'All')
    })
}

export function filterUsersByAreaGroup(area: AreaStruct, users: UserStruct[]) {
    return users.forEach(user => {
        return (
            (user.areaGroups && user.areaGroups.includes(area.group as string)) ||
            (user.areaGroups && user.areaGroups.length === 1 && user.areaGroups[0] === 'All')
        )
    })
}

export function filterIssuesWithHastags(issues: IssueStruct[], hashTagValue: string) {
    const normHashTagValue = normalizeString(hashTagValue).toLocaleLowerCase()

    const issuesWithHastags = issues.filter(issue => {
        const normIssueName = normalizeString(issue.name).toLocaleLowerCase()
        const index = normIssueName.search(normHashTagValue)
        if (index !== -1) {
            return true
        }
        return false
    })
    return issuesWithHastags
}

export function checkIfQuotes(string: string) {
    const first = string.charAt(0)
    const last = string.charAt(string.length - 1)
    if (first === '"' || first === '“' || first === '”') {
        if ((last === '"' || last === '“' || last === '”') && string.length > 1) {
            return true
        }
    }
    return false
}

export function removeQuotes(string: string) {
    let outputString = string.replace(/“/g, '')
    outputString = outputString.replace(/”/g, '')
    outputString = outputString.replace(/"/g, '')
    return outputString
}

export function searchSet(issue: IssueStruct) {
    let issueString = null
    if (!issue.area) {
        issueString = issue.name + ' ' + issue.issueNumber
    } else {
        issueString =
            issue.issueNumber +
            ' ' +
            issue.name +
            ' ' +
            issue.area.name +
            ' ' +
            issue.area.description +
            ' ' +
            issue.area.address +
            ' ' +
            issue.area.group +
            ' ' +
            issue.lastToucherName +
            ' ' +
            issue.assignedTo
    }
    return issueString
}

export function areaSearchSet(area: Partial<AreaWithTask> & { assignedTo?: Partial<UserStruct>[] }) {
    let assignedToNames = ''
    let assignedToInitials = ''
    if (area.task && Array.isArray(area.task.assignedTo)) {
        assignedToNames = area.task.assignedTo.map(n => n.name).join(' ')
        assignedToInitials = area.task.assignedTo.map(i => i.initials).join(' ')
    }
    if (area.assignedTo && Array.isArray(area.assignedTo)) {
        assignedToNames = area.assignedTo.map(n => n.name).join(' ')
        assignedToInitials = area.assignedTo.map(i => i.initials).join(' ')
    }
    let cleaningStatus = area.cleaningStatus ? area.cleaningStatus : area.status
    if (cleaningStatus === c.CLEANING_STATUS_DIRTY) {
        cleaningStatus = 'unclean'
    } else if (
        cleaningStatus === c.CLEANING_STATUS_DO_NOT_DISTURB ||
        cleaningStatus === c.CLEANING_STATUS_OUT_OF_SERVICE ||
        cleaningStatus === c.CLEANING_STATUS_NO_SERVICE
    ) {
        cleaningStatus = cleaningStatus.replace(/-/g, ' ')
    } else if (!cleaningStatus) {
        cleaningStatus = 'unknown'
    }

    return (
        area.name +
        ' ' +
        area.group +
        ' ' +
        area.description +
        ' ' +
        area.note +
        ' ' +
        cleaningStatus +
        ' ' +
        area.occupancy +
        ' ' +
        assignedToNames +
        ' ' +
        assignedToInitials
    )
}

export function issueSearchSet(issue: IssueStruct) {
    let issueString = ''

    if (!issue.area) {
        issueString = `${issue.name} ${issue.issueNumber}`
    } else {
        const assignedContacts = issue.assignedContacts ? issue.assignedContacts.map(i => i.initials).join(' ') : ''
        issueString = `${issue.issueNumber} ${issue.name} ${issue.area.name} ${issue.area.description} ${issue.area.address} ${issue.area.group} ${assignedContacts} ${issue.assignedTo}`
    }

    return issueString
}

export function normalizeString(string: string) {
    return unorm
        .nfd(string)
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\+/g, '\\+')
        .replace(/\\/g, '')
}

export function search(value: string, searchDomain: IssueStruct[], searchSetFunction: ((issue: IssueStruct) => string) | null = null) {
    let matches = null
    if (value === '' || value === ' ' || value === '  ' || value === '   ') {
        matches = searchDomain
    } else {
        let searchString = removeQuotes(value)
        searchString = normalizeString(searchString)
        matches = searchDomain.filter(issue => {
            let issueString = null
            if (searchSetFunction) {
                issueString = searchSetFunction(issue)
            } else {
                issueString = searchSet(issue)
            }
            issueString = normalizeString(issueString)

            return issueString.toLocaleLowerCase().search(searchString.toLocaleLowerCase()) >= 0
        })
    }
    matches.sort((a, b) => sortTimeStampDescending(a.updated, b.updated))
    return matches
}

export type AreaWithTask = AreaStruct & { task: TaskStruct; status: string }
export function searchAreas<T extends Partial<AreaStruct>>(
    value: string,
    searchDomain: T[],
    searchSetFunction: ((area: T) => string) | null = null
): Partial<AreaStruct>[] {
    let matches = null
    if (value === '' || value === ' ' || value === '  ' || value === '   ') {
        matches = searchDomain
    } else {
        let searchString = removeQuotes(value)
        searchString = normalizeString(searchString)
        matches = searchDomain.filter(area => {
            let areaString = null
            if (searchSetFunction) {
                areaString = searchSetFunction(area)
            } else {
                areaString = areaSearchSet(area)
            }
            areaString = normalizeString(areaString)

            return areaString.toLocaleLowerCase().search(searchString.toLocaleLowerCase()) >= 0
        })
    }
    matches.sort((a, b) => sortTimeStampDescending(a.updated!, b.updated!))
    return matches
}

export function filterIssuesSearchAndDisplay(
    issues: IssueStruct[],
    filterPriority: boolean,
    searchValue: string,
    callback: (issues: IssueStruct[]) => void,
    searchSetFunction: ((issue: IssueStruct) => string) | null = null
) {
    let issuesWithHastags = null

    if (searchValue.startsWith('#') && searchValue.indexOf(' ') === -1) {
        issuesWithHastags = filterIssuesWithHastags(issues, searchValue)
    } else {
        issuesWithHastags = issues
    }

    let searchedAndFilteredIssues = issuesWithHastags
    if (filterPriority) {
        searchedAndFilteredIssues = searchedAndFilteredIssues.filter(issue => issue.priority === true)
    }

    if (searchValue !== '') {
        searchedAndFilteredIssues = search(searchValue, searchedAndFilteredIssues, searchSetFunction)
    }

    callback(searchedAndFilteredIssues)
}

export function getNextIssueStatus(status: IssueStruct['status'] | typeof c.ISSUE_ANYSTATUS, all = true) {
    let nextStatus = status
    if (status === c.ISSUE_ANYSTATUS) {
        nextStatus = c.ISSUE_OPEN
    } else if (status === c.ISSUE_OPEN) {
        nextStatus = c.ISSUE_ASSIGNED
    } else if (status === c.ISSUE_ASSIGNED) {
        nextStatus = c.ISSUE_COMPLETE
    } else if (status === c.ISSUE_COMPLETE) {
        nextStatus = c.ISSUE_DELETED
    } else if (status === c.ISSUE_DELETED) {
        if (all) {
            nextStatus = c.ISSUE_ANYSTATUS
        } else {
            nextStatus = c.ISSUE_OPEN
        }
    }

    return nextStatus
}

function isNumeric(num: number) {
    return !isNaN(num)
}

export function formatAreaGroups(areaGroups: string[]) {
    let areaGroupsField = areaGroups.length === 0 ? 'None' : ''
    areaGroups.forEach((areaGroup, counter) => {
        if (counter === 0) {
            areaGroupsField = areaGroup
        } else {
            areaGroupsField += ', ' + areaGroup
        }
    })
    return shortenLongName(areaGroupsField, 31)
}

/*
    TODO - rewrite this mess
*/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function sortAreas(areas) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const areasGroupSorted = areas.sort((a, b) => {
        return a.group.toLowerCase().localeCompare(b.group.toLowerCase(), 'en', { numeric: true, sensitiviy: 'base' })
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const areasMap = {}

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    areasGroupSorted.forEach(area => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (areasMap[area.group]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            areasMap[area.group].push(area)
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            areasMap[area.group] = [area]
        }
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const areasMapKeys = Object.keys(areasMap)

    areasMapKeys.forEach(groupKey => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        areasMap[groupKey].sort((a, b) => {
            const aArr = a.name.match(/[a-zA-Z]+|[0-9]+/g)
            const bArr = b.name.match(/[a-zA-Z]+|[0-9]+/g)

            if (isNumeric(aArr[0]) && isNumeric(bArr[0])) {
                return aArr[0] - bArr[0] || a.name.localeCompare(b.name)
            } else {
                return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
            }
        })
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let areasToReturn = []

    Object.values(areasMap).forEach(a => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        areasToReturn = areasToReturn.concat(a)
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return areasToReturn
}

export function getUniqueKeyArray(items: IssueStruct['items']) {
    const result = []
    const map = new Map()
    for (const item of items) {
        if (!map.has(item.key)) {
            map.set(item.key, true) // set any value to Map
            result.push(item)
        }
    }
    return result
}

export const formatAreaToOption = (area: AreaStruct) => {
    const { key, name, description, group } = area
    return {
        value: key,
        label: shortenLongName(name, 19) + ' ' + shortenLongName(description || '', 30),
        subText: shortenLongName(group || '', 30)
    }
}

export const formatUserToOption = (user: UserStruct) => {
    const { key, name, initials } = user
    return {
        value: key,
        label: name,
        initials
    }
}

/*
    TODO - define proper types
*/
export const formatPhoneInputValue = (inputValue: string, selectedCountry: any) => {
    const dialCode = selectedCountry?.dialCode
    const pn = dialCode ? dialCode + inputValue : inputValue
    const parsedNumber = parsePhoneNumberFromString(selectedCountry ? pn : '')
    const value = parsedNumber ? parsePhoneNumberFromString(pn)?.formatNational() : inputValue.replace(/\D/g, '')

    return value
}

export function isPhoneNumberValid(phoneNumber: string, countryCode: CountryCode) {
    // avoiding getting errors/exceptions from libphonenumber-js library
    if (!phoneNumber || phoneNumber.length < 6) return false
    if (!countryCode) return false

    const parsedNumber = parsePhoneNumber(phoneNumber, countryCode)
    const isPossible = parsedNumber.isPossible() && parsedNumber.isValid()

    return !(!isPossible || parsedNumber.country !== countryCode)
}

/*
    TODO - define proper types
*/
export const phoneNumberValidationTest = (inputValue: string, selectedCountry: any) => {
    const phoneNumber = selectedCountry.dialCode + inputValue
    const countryCode = selectedCountry.key as CountryCode
    return isPhoneNumberValid(phoneNumber, countryCode)
}

export const removeUndefinedValues = <T extends object>(obj: T): T => {
    return Object.fromEntries(
        Object.entries(obj)
            .filter(([_, value]) => value !== undefined)
            .map(([k, v]) => [k, v !== null && typeof v === 'object' && !Array.isArray(v) ? removeUndefinedValues(v) : v])
    ) as T
}

export const arraysAreEqual = (arr1: string[], arr2: string[]) => {
    const set1 = new Set(arr1)
    const set2 = new Set(arr2)
    if (set1.size !== set2.size) return false
    for (const value of set1) {
        if (!set2.has(value)) return false
    }
    return true
}
