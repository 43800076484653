import moment from 'moment-timezone'
import { CleaningStruct } from './firestore-structs'

export interface TimerDisplay {
    hours: string
    min: string
    sec: string
}

export const getLastStopTime = (stops: number[] | undefined): number | null => {
    return stops?.length ? stops[stops.length - 1] : null
}

export const getEndTime = (cleaningObj: CleaningStruct): number | null => {
    return cleaningObj.end ?? getLastStopTime(cleaningObj.stop) ?? null
}

export function getHousekeepingDuration(cleaningObj: CleaningStruct, timeUnit: 'seconds' | 'minutes' | 'hours') {
    let totalDuration = 0
    const now = moment().valueOf()

    const endTime = moment(getEndTime(cleaningObj) ?? now)

    if (cleaningObj.pause && cleaningObj.pause.length > 0) {
        const stopBeforePlay = cleaningObj.stop && cleaningObj.stop.length > 1 ? cleaningObj.stop[cleaningObj.stop.length - 2] : null

        // The start of the current cleaning cycle is the first play after the stop of the previous cycle
        const startTime = stopBeforePlay
            ? cleaningObj.play.find(time => moment(time).isSameOrAfter(stopBeforePlay)) ?? cleaningObj.start
            : cleaningObj.start

        const playTimes = cleaningObj.play.filter(time => moment(time).isSameOrAfter(startTime))
        const pauseTimes = cleaningObj.pause.filter(time => moment(time).isSameOrAfter(startTime))

        for (let i = 0; i < pauseTimes.length; i++) {
            if (i >= playTimes.length) break
            const playTime = moment(playTimes[i])
            const pauseTime = moment(pauseTimes[i])
            totalDuration += pauseTime.diff(playTime, timeUnit)
        }

        if (playTimes.length > pauseTimes.length) {
            const lastPlayTime = playTimes[playTimes.length - 1]
            totalDuration += endTime.diff(lastPlayTime, timeUnit)
        }
    } else {
        const startTime = moment(cleaningObj.play[cleaningObj.play.length - 1] ?? cleaningObj.start)
        totalDuration = endTime.diff(startTime, timeUnit)
    }

    return totalDuration
}

export function getTimerDisplay(cleaningObj: CleaningStruct | null): TimerDisplay {
    if (!cleaningObj)
        return {
            hours: '00',
            min: '00',
            sec: '00'
        }

    const sinceStart = getHousekeepingDuration(cleaningObj, 'seconds')

    const timerDisplay = {
        hours: moment().hour(0).minute(0).second(sinceStart).format('HH'),
        min: moment().hour(0).minute(0).second(sinceStart).format('mm'),
        sec: moment().hour(0).minute(0).second(sinceStart).format('ss')
    }

    return timerDisplay
}

export function durationToText(cleaningObj: CleaningStruct, showPostFix = false) {
    const min = getHousekeepingDuration(cleaningObj, 'minutes')

    const num = min
    const hours = num / 60
    const rhours = Math.floor(hours)
    const minutes = (hours - rhours) * 60
    const rminutes = Math.round(minutes)
    let hoursPostfix = ''
    let minPostfix = ''

    if (rhours > 1) {
        hoursPostfix = 's'
    }

    if (minutes !== 1) {
        minPostfix = 's'
    }

    let postfix = ''
    if (showPostFix) {
        postfix = ' ago'
    }

    if (rhours >= 1) {
        return rhours + ' hour' + hoursPostfix + ' and ' + rminutes + ' minute' + minPostfix + postfix
    }
    return rminutes + ' minute' + minPostfix + postfix
}
