import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { getBrand, getNavBarNOAuthLogo } from '@shared/brand'
import { ContentWrap, CountBox, CountWrap, LogoWrap, SignUpWelcomeWrapper, Rectangles } from './style'
import RectangularButton from '../../../components/buttons/RectangularButton'
import { AuthContext } from '../../Auth/AuthContext'
import { removeWorkflowInstance } from '../../../utils/signup-flow-template/signup-workflow-instance'
import { currentIntegrationKey } from '../signup-worflow-templates'
import firebase from '../../../utils/firebase'
import Count from './Count'
import { toast } from 'react-toastify'
import { useIsMobile } from '../../../utils/hooks/useIsMobile'
import { HOUSEKEEPING } from '../../../navigation/url-constants'
import { renderRectangles, toastErrorStyle } from '../../../utils/styles'
import withErrorCatching from '../../../utils/hocs/withErrorCatching'
import ActivityView from '../../../utils/loading-view'
import { useFeatureToggle } from '../../../../app/features'

const MAX_CLIENT_SIDE_IMPORT_TIME = 70 * 1000

const SignUpWelcome = ({ catchError }) => {
    const { isFeatureOn } = useFeatureToggle()
    const useNewInitialImport = isFeatureOn('new-initial-import-procedure')

    const history = useHistory()
    const isMobile = useIsMobile()
    const { currentUser, setStopAuthListener, isAuthorized } = useContext(AuthContext)
    const [areaCount, setAreaCount] = useState(0)
    const [bookingCount, setBookingCount] = useState(0)
    const [areaFetchDone, setAreaFetchDone] = useState(false)
    const [areaCountDone, setAreaCountDone] = useState(false)
    const [bookingFetchDone, setBookingFetchDone] = useState(false)
    const [bookingCountDone, setBookingCountDone] = useState(false)
    const [accountReady, setAccountReady] = useState(false)
    const [loading, setLoading] = useState(true)
    const lastAreaCount = useRef(0)
    const lastBookingCount = useRef(0)

    const brandLogo = getNavBarNOAuthLogo()
    const brand = getBrand()
    const brandColor = brand.navBarColor

    /**
     * Listen to the initial-imports Firestore collection for completion
     * of initial PMS sync. If successful, cancel the "taking too long"
     * handler.
     * @param {Promise<void>} longImportHandler
     */
    function createImportCompleteHandler(longImportHandler) {
        /** @param {firebase.default.firestore.DocumentSnapshot} snap */
        return snap => {
            if (!snap.exists) return
            const data = snap.data()

            if ('status' in data && data.status === 'complete') {
                console.log('Imported rooms and bookings successfully')
                clearTimeout(longImportHandler)
                setAccountReady(true)
            } else {
                // do something?
            }
        }
    }

    const onAreaUpdate = snap => {
        setAreaCount(snap.size)
        setAreaFetchDone(true)
    }

    const onBookingUpdate = snap => {
        setBookingCount(snap.size)
        setBookingFetchDone(true)
    }

    const onAreaCountEnd = () => {
        lastAreaCount.current = areaCount
        if (!useNewInitialImport) setAreaCountDone(true)
    }

    const onBookingCountEnd = () => {
        lastBookingCount.current = bookingCount
        if (!useNewInitialImport) setBookingCountDone(true)
    }

    useEffect(() => {
        setStopAuthListener(true)
        removeWorkflowInstance(currentIntegrationKey)
    }, [])

    useEffect(() => {
        if (isAuthorized) {
            setLoading(false)
        } else {
            setStopAuthListener(false)
        }
        return () => {}
    }, [isAuthorized])

    useEffect(() => {
        let unsubscribeAreas
        let unsubscribeBookings
        let unsubscribeImportCompletion

        // Old way: listen to bookings and areas imports until
        // things have probably stop importing, then set account to
        // ready. Does not handle max client-side timeout well.
        function oldInitialImport() {
            try {
                const db = firebase.firestore()

                const areaRef = db
                    .collection('areas')
                    .where('organizationKey', '==', currentUser.organizationKey)
                    .where('visible', '==', true)
                unsubscribeAreas = areaRef.onSnapshot(onAreaUpdate)

                setTimeout(() => {
                    const bookingsRef = db.collection('bookings').where('organizationKey', '==', currentUser.organizationKey)
                    unsubscribeBookings = bookingsRef.onSnapshot(onBookingUpdate)
                }, 500)
            } catch (error) {
                console.error(error.message)
                toast.error(error.message, toastErrorStyle)
            }

            return () => {
                if (unsubscribeAreas) {
                    unsubscribeAreas()
                }
                if (unsubscribeBookings) {
                    unsubscribeBookings()
                }
            }
        }

        // New way: listen to an explicit completion signal from the
        // server. Set accountReady to true when the done signal is received
        // from the initial-imports collection, or a  maximum
        // client-side timeout is exceeded. If the import is taking too
        // long, tell user import is still going on, and set accountReady
        // anyway.
        function newInitialImport() {
            try {
                const db = firebase.firestore()

                // If we are having a long import, redirect to a pleasant
                // soft error state.
                const longImportTimeout = setTimeout(() => {
                    console.warn('Import is taking longer than expected.')
                    setAccountReady(true) // TODO Need to set some kind of intermediate state
                }, MAX_CLIENT_SIDE_IMPORT_TIME)

                const importCompletion = db.collection('initial-imports').doc(currentUser.organizationKey)
                const importCompletionHandler = createImportCompleteHandler(longImportTimeout)
                const importErrorHandler = err => {
                    console.error('error with import completion', err)
                }

                unsubscribeImportCompletion = importCompletion.onSnapshot(importCompletionHandler, importErrorHandler)

                const areaRef = db
                    .collection('areas')
                    .where('organizationKey', '==', currentUser.organizationKey)
                    .where('visible', '==', true)
                unsubscribeAreas = areaRef.onSnapshot(onAreaUpdate)

                setTimeout(() => {
                    const bookingsRef = db.collection('bookings').where('organizationKey', '==', currentUser.organizationKey)
                    unsubscribeBookings = bookingsRef.onSnapshot(onBookingUpdate)
                }, 500)
            } catch (error) {
                console.error(error.message)
                toast.error(error.message, toastErrorStyle)
            }

            return () => {
                if (unsubscribeAreas) {
                    unsubscribeAreas()
                }
                if (unsubscribeBookings) {
                    unsubscribeBookings()
                }

                if (unsubscribeImportCompletion) {
                    unsubscribeImportCompletion()
                }
            }
        }

        if (!loading) {
            return useNewInitialImport ? newInitialImport() : oldInitialImport()
        }
    }, [loading])

    useEffect(() => {
        if (!useNewInitialImport) {
            console.log('Setting up effect for old import method')
            if (areaCountDone && bookingCountDone && areaFetchDone && bookingFetchDone) {
                setAccountReady(true)
            }
        }
    }, [areaCountDone, areaFetchDone, bookingCountDone, bookingFetchDone])

    const onButtonClick = () => {
        if (isMobile) {
            const iosApp = brand.urls.appStore
            const androidApp = brand.urls.playStore
            const device = navigator.userAgent
            const url = device.includes('iPhone') || device.includes('iPad') ? iosApp : androidApp

            return (window.location.href = url)
        }

        return history.push(HOUSEKEEPING.ONBOARDING)
    }

    return (
        <SignUpWelcomeWrapper>
            {loading && <ActivityView />}
            {!loading && (
                <div className="content-container">
                    <ContentWrap>
                        <LogoWrap>
                            <img src={brandLogo.img} style={brandLogo.style} />
                            {!isMobile && <Rectangles>{renderRectangles(6)}</Rectangles>}
                        </LogoWrap>

                        <CountWrap>
                            <h1>Importing rooms and {!isMobile && <br />} bookings</h1>
                            <span>Usually takes between 30-60 seconds</span>

                            <div>
                                <CountBox done={accountReady}>
                                    <label>Rooms</label>

                                    <Count start={lastAreaCount.current} end={areaCount} onEnd={onAreaCountEnd} />
                                </CountBox>

                                <CountBox done={accountReady}>
                                    <label>Bookings</label>

                                    <Count start={lastBookingCount.current} end={bookingCount} onEnd={onBookingCountEnd} />
                                </CountBox>
                            </div>

                            <RectangularButton
                                onClick={onButtonClick}
                                disabled={!accountReady}
                                margin={'64px 0 0 0'}
                                width={'343px'}
                                height={'64px'}
                                backgroundColor={brandColor}>
                                {isMobile ? 'Download the App' : 'Take me to my account'}
                            </RectangularButton>
                        </CountWrap>
                    </ContentWrap>
                </div>
            )}
            {isMobile && <Rectangles>{renderRectangles(5)}</Rectangles>}
        </SignUpWelcomeWrapper>
    )
}

export default withErrorCatching(SignUpWelcome)
