import {
    getWorkflowInstance,
    integrationStartUrl,
    storeWorkflowInstance,
    removeWorkflowInstance
} from '../../utils/signup-flow-template/signup-workflow-instance'
import { currentIntegrationKey } from './signup-worflow-templates.js'

export const errorModalConfigs = {
    button: {
        text: 'Try again',
        onClick: () => {
            const URL = integrationStartUrl().url
            removeWorkflowInstance(currentIntegrationKey)
            integrationStartUrl().remove()

            window.location.href = URL
        }
    }
}

export const startWorkflow = ({ bus, imageUrl, workflowTemplate, currentStep, history }, brand = null, reconnect = false) => {
    removeWorkflowInstance(currentIntegrationKey)

    const params = new URLSearchParams(history.location.search)
    let workflowInstance = getWorkflowInstance(currentIntegrationKey)

    const settings = {}
    params.forEach((value, key) => (settings[key] = value))

    if (workflowInstance !== 'not found') {
        const reconnecting = workflowInstance.getInput('reconnect') === true
        if (!reconnecting) {
            // cleanup unrelated pre-existing instance
            removeWorkflowInstance(currentIntegrationKey)
            workflowInstance = workflowTemplate.start()
        }
    } else {
        workflowInstance = workflowTemplate.start()
    }

    settings.bus = bus
    settings.imageUrl = imageUrl
    reconnect && (settings.reconnect = reconnect)
    brand && (settings.brand = brand)

    workflowInstance.completeStep(currentStep, settings)
    storeWorkflowInstance(currentIntegrationKey, workflowInstance)
    integrationStartUrl(history.location.pathname + history.location.search)

    history.push(workflowInstance.nextStep())
}
