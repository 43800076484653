import React from 'react'
import CountUp from 'react-countup'

const Count = ({ start, end, onEnd }) => {
    return (
        <CountUp start={start} end={end} duration={10} delay={0} onEnd={onEnd} redraw preserveValue={true}>
            {({ countUpRef }) => (
                <>
                    <span ref={countUpRef} />
                </>
            )}
        </CountUp>
    )
}

export default Count
